import { useDispatch } from 'react-redux';

import AuthPageWrapper from '@/components/authPage/AuthPageWrapper';
import Layout from '@/components/layout/LayoutWrapper';
import { InitRequest, useInitState } from '@/hooks/store';
import { useComponentDidMount } from '@/hooks/utils/useComponentDidMount';
import { setAuthFormMode } from '@/store/authFormMode/actions';

const Login = () => {
	const dispatch = useDispatch();

	useInitState({
		requests: [InitRequest.USER, InitRequest.DEVICE],
	});

	useComponentDidMount(() => {
		dispatch(setAuthFormMode('login'));
	});

	return (
		<Layout pageName="login">
			<AuthPageWrapper />
		</Layout>
	);
};

export default Login;
